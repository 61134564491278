<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-card class="px-4">
      <v-data-table
        :headers="headers"
        :items="tiket"
        :items-per-page="25"
        class="elevation-0"
        mobile-breakpoint="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="mt-4 px-8 pt-4 pb-10"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cari"
                color="purple"
                single-line
                hide-details
                clearable
                outlined
                dense
                @keyup.enter.native="filterData"
                @click:append="filterData"
                @click:clear="filterClear"
              />
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.actions="{ item }">
          <div
            class="action-container"
            style="
            width: 36px;
            height: 29px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #F2F2F2;
            box-sizing: border-box;
            border-radius: 4px;
            cursor: pointer;
          "
            @click="toDetail(item)"
          >
            <v-icon
              class="action-icon"
              style="font-size: 16px"
            >
              fas fa-eye
            </v-icon>
          </div>
        </template>

        <template v-slot:item.status_string="{ item }">
          <v-chip :color="'#' + item.status_color">
            <span style="color: #FFFFFF">
              {{ item.status_string }}
            </span>
          </v-chip>
        </template>

        <template v-slot:footer>
          <div class="text-center my-5 py-3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="purple"
              :total-visible="5"
              @input="onChangePage"
            />
            <span style="font-size: 12px; font-style: italic;">
              Page {{ page }} of {{ pageCount || 1 }}
            </span>
          </div>
          <v-row class="mt-n16">
            <v-col class="text-right pr-8">
              <span style="font-size: 12px; font-style: italic;">
                {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
              </span>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <!-- DIALOG -->
    <v-dialog
      v-model="dialog"
      max-width="600"
    >
      <template v-if="selectedTiket !== null">
        <v-card class="px-4 py-4">
          <v-row
            style="
          background: #7A1878;
          "
          >
            <v-col class="font-common-white">
              {{ selectedTiket.title }}
            </v-col>

            <v-col class="text-right font-common-white">
              <v-icon
                style="
                cursor: pointer;
                color: #FFFFFF;"
                @click="dialog = false"
              >
                fas fa-times
              </v-icon>
            </v-col>
          </v-row>

          <!-- <v-row
            style="
            border-top: 1px solid white;
          background: #7A1878;
          "
          >
            <v-col class="font-common-white">
              {{ selectedTiket.submit_by_user_name }}
            </v-col>

            <v-col class="text-right font-common-white">
              {{ selectedTiket.created_time }}
            </v-col>
          </v-row> -->

          <v-row>
            <v-col class="px-6">
              <div
                class="mt-5 mb-n5 px-3 py-3"
                style="
                  border: 1px solid #F2F2F2;
                  box-sizing: border-box;
                  border-radius: 4px;
                  background: #F2F2F2;
                  color: #B2B2B2;
                "
              >
                <div
                  class="font-common-bold mb-1"
                  style="color: #7A1878"
                >
                  {{ selectedTiket.submit_by_user_name }}
                </div>

                <div class="font-common-black">
                  {{ selectedTiket.title_description }}
                </div>

                <div
                  class="d-flex"
                  style="justify-content: flex-end"
                >
                  {{ selectedTiket.created_time }}
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="px-6">
              <div
                v-for="(v, i) in selectedTiket.thread"
                :key="'ticket-' + i"
                class="mt-5 px-3 py-3"
                style="
                  border: 1px solid #F2F2F2;
                  box-sizing: border-box;
                  border-radius: 4px;
                "
                :style="v.bubble_chat_pos === 'L' ? 'background: #F2F2F2; color: #B2B2B2;' : 'background: #7A1878; color: #FFFFFF;' "
              >
                <div
                  class="font-common-bold"
                  :style="v.bubble_chat_pos === 'L' ? 'color: #7A1878;' : 'color: #FFC709;' "
                >
                  {{ v.submit_by_user_name }}
                </div>

                <div
                  class="font-common"
                  :style="v.bubble_chat_pos === 'L' ? 'color: black;' : 'color: white;' "
                >
                  {{ v.content }}
                </div>

                <template v-if="v.documents.length > 0">
                  <div
                    class="mt-5 mb-2 font-common"
                    :style="v.bubble_chat_pos === 'L' ? 'color: black;' : 'color: white;' "
                  >
                    Lampiran :
                  </div>

                  <div
                    v-for="(x, y) in v.documents"
                    :key="'thread-doc-' + y"
                  >
                    <span :style="v.bubble_chat_pos === 'L' ? 'color: black;' : 'color: white;' ">
                      {{ x.name }}
                    </span>

                    <span
                      style="cursor: pointer"
                      @click="downloadLampiran(x.path)"
                    >
                      <v-icon color="white">
                        mdi-arrow-down-bold-circle
                      </v-icon>
                    </span>
                  </div>
                </template>

                <div
                  class="d-flex"
                  style="justify-content: flex-end"
                >
                  {{ v.created_time }}
                </div>
              </div>

              <template v-if="ticketMod">
                <hr
                  style="
                margin-top: 180px;
                border: 1px solid #E6E6E6;
              "
                >

                <div class="mt-6">
                  <v-textarea
                    v-model="isiPesan"
                    class="mt-3"
                    color="purple"
                    outlined
                    no-resize
                    placeholder="Ketik Pesan Anda di sini"
                  />
                </div>

                <!-- <div class="font-common">
                Upload File
              </div> -->

                <div
                  class="d-flex mb-6"
                  style="justify-content: space-between"
                >
                  <template v-if="lampiran === null">
                    <div
                      class="d-flex mr-2"
                      style="
                    justify-content: center;
                    align-items: center;
                    width: 461px;
                    height: 40px;
                    background: #FAFAFA;
                    border: 1px dashed #E6E6E6;
                    box-sizing: border-box;
                    border-radius: 4px;
                    cursor: pointer;
                  "
                      @click="upload"
                    >
                      <span style="color: #E5E5E5">
                        Masukkan File
                      </span>
                    </div>
                  </template>

                  <template v-else>
                    <span class="font-common-bold mt-2">
                      {{ lampiran.file_name }}
                    </span>
                  </template>

                  <div>
                    <v-btn
                      outlined
                      style="
                      border: 1px solid #E6E6E6;
                    "
                      @click="upload"
                    >
                      <span
                        class="font-common-bold text-capitalize"
                        style="color: #7A1878"
                      >
                        Upload File
                      </span>
                    </v-btn>
                  </div>

                  <v-file-input
                    id="upload-lampiran"
                    style="display: none"
                    @change="uploadLampiran"
                  />
                <!-- {{ lampiran }} -->
                </div>

                <hr style="border: 1px solid #E6E6E6">

                <div
                  class="d-flex pt-2 mt-4 mb-2"
                >
                  <v-btn
                    block
                    class="mr-0"
                    style="
                    background: #7A1878;
                    border: none;
                    outline: none;
                  "
                    @click="reply"
                  >
                    <span
                      class="font-common-white-bold text-capitalize"
                      style="color: #FFFFFF"
                    >
                      Kirim Pesan
                    </span>
                  </v-btn>
                </div>

                <div class="mb-3">
                  <v-btn
                    class="elevation-0"
                    block
                    style="background: #CCCCCC"
                    @click="konfirmasiDialog = true"
                  >
                    <span class="font-common-bold text-capitalize">
                      Tutup Tiket
                    </span>
                  </v-btn>
                </div>
              </template>
              <template v-else>
                <v-row>
                  <v-col class="font-common text-center">
                    Tidak ada akses
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </v-card>
      </template>
    </v-dialog>

    <!-- KONFIRMASI DIALOG -->
    <v-dialog
      v-model="konfirmasiDialog"
      width="400"
    >
      <v-card class="px-4 py-8">
        <v-row>
          <v-col class="font-common-bold text-center">
            Apakah anda yakin menutup tiket ini?
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center">
            <v-btn
              class="elevation-0 text-capitalize font-common-bold"
              style="background: #7A1878"
              @click="closeTicket"
            >
              Yakin
            </v-btn>

            <v-btn
              class="elevation-0 text-capitalize font-common-bold"
              style="background: #CCCCCC"
              @click="konfirmasiDialog = false"
            >
              Batal
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      dialog: false,

      // Tiket:
      search: '',
      headers: [
        { text: 'Tanggal', align: 'start', value: 'last_update_time', sortable: false },
        { text: 'Nama Entitas', value: 'submit_by_user_name' },
        { text: 'Tipe', value: 'submit_by_user_type', sortable: false },
        { text: 'Judul', value: 'title' },
        { text: 'Status', value: 'status_string' },
        { text: '', value: 'actions', sortable: false },
      ],
      tiket: [],

      isAllowDetail: false,
      isAllowVerification: false,

      selectedTiket: null,

      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,

      lampiran: null,
      isiPesan: '',

      konfirmasiDialog: false,

      // Permission:
      userMenus: localStorage.getItem('userMenus').split(','),
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),

      ticketMod () {
        let v = false
        if (this.userMenus.includes('TICKET_MOD')) v = true

        return v
      },
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
      // this.getPerusahaan(1, this.itemsPerPage)
    },

    methods: {
      initialize (page, limit, filter) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')

        // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
        }

        axios.post('/v2/t/ticket', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.tiket = res.data.data.data

            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      toDetail (item) {
        const requestBody = {
          ticket_id: item.hash,
        }

        axios.post('/v2/t/ticket/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.selectedTiket = res.data.data[0]
            this.lampiran = null
            this.dialog = true
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      uploadLampiran (e) {
        // console.log(e)
        const baseCDNurl = process.env.VUE_APP_CDN_BASE_URL
        const config = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
        const requestBody = new FormData()
        requestBody.append('image', e)
        // requestBody.append('dest', 'orderDoc')

        axios.post(baseCDNurl + '/v1/g/ticket/uploadFile', requestBody, config).then((res) => {
          if (res.data.status === 200) {
            // console.log(res.data.data)
            this.lampiran = res.data.data
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      reply () {
        const files = []
        if (this.lampiran !== null) {
          files.push(this.lampiran.tmp_file_hash)
          // files = JSON.stringify(files)
        }

        const requestBody = {
          ticket_id: this.selectedTiket.hash,
          files_up: JSON.stringify(files),
          content: this.isiPesan,
        }

        axios.post('/v2/t/ticket/reply', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success('Berhasil')
            this.lampiran = null
            this.isiPesan = ''
            this.dialog = false
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      closeTicket () {
        const requestBody = {
          ticket_id: this.selectedTiket.hash,
        }

        axios.post('/v2/t/ticket/close', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.$toast.success('Berhasil')
            this.dialog = false
            this.konfirmasiDialog = false
            this.initialize()
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      downloadLampiran (url) {
        window.open(url, '_blank')
      },

      upload () {
        document.getElementById('upload-lampiran').click()
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage)
      },

      filterData () {
        this.initialize(1, this.itemsPerPage, this.search)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage)
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #7A1878;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  color: #7A1878;
}

/*
tbody > tr:hover {
  background-color: transparent !important;
}

.right-arrow::before {
  content: " ";
  height: 0;
  position: absolute;
  width: 0;
  z-index: 1;
  border: medium solid red;
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent red;
}

.left-arrow::before {
  content: " ";
  height: 0;
  position: absolute;
  width: 0;
  z-index: 1;
  border: medium solid red;
  border-width: 10px 10px 10px 0;
  border-color: transparent red transparent transparent;
} */
</style>
